import React from "react";

import { Box, S, Text } from "ve";

import { parseDate, useLocalDateFormatter, CalendarDateString } from "@puzzle/utils";

export const GraphDateRange = ({
  startDate,
  endDate,
  css,
}: {
  startDate?: CalendarDateString;
  endDate?: CalendarDateString;
  css?: React.CSSProperties;
}) => {
  const monthFormatter = useLocalDateFormatter();

  if (!startDate || !endDate) {
    return null;
  }

  const startDateFormatted = monthFormatter.customFormat(parseDate(startDate), "MMM ''yy");
  const endDateFormatted = monthFormatter.customFormat(parseDate(endDate), "MMM ''yy");

  return (
    <Box
      css={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: S.$1h,
        paddingRight: S.$1h,
        ...css,
      }}
    >
      <Text variant="bodyXS" color="gray300" weight="bold">
        {startDateFormatted}
      </Text>
      <Text variant="bodyXS" color="gray300" weight="bold">
        {endDateFormatted}
      </Text>
    </Box>
  );
};
