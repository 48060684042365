import React, { useState } from "react";
import { ChevronRight, Exclamation } from "@puzzle/icons";
import { Button, Dialog, Tag, colors } from "@puzzle/ui";
import { Box } from "ve";
import useAppRouter, { RouteParams } from "lib/useAppRouter";
import { Route } from "lib/routes";

type EstimateTagProp = {
  modalTitle: React.ReactNode;
  modalBody: React.ReactNode;
};

export const EstimatedTag = ({ modalTitle, modalBody }: EstimateTagProp) => {
  const [open, setOpen] = useState(false);
  const { goToPath } = useAppRouter();

  return (
    <>
      <Dialog
        style={{ zIndex: "99" }}
        modal
        onClick={(e) => {
          // Annoying but necessary because metric card expanded will catch clicks on it
          e.stopPropagation();
          e.preventDefault();
        }}
        open={open}
        onOpenChange={(open) => setOpen(open)}
        size="xsmall"
      >
        <Dialog.Title>{modalTitle}</Dialog.Title>
        <Dialog.Body>{modalBody}</Dialog.Body>
        <Dialog.Footer>
          <Dialog.Actions>
            <Button
              variant="secondary"
              onClick={() => {
                  goToPath(
                    Route.transactions,
                    {
                      path: Route.transactions,
                      query: "showHighPriorityReview=true"
                    } as RouteParams,
                  );
              }}
              suffix={<ChevronRight />}
            >
              High Priority Transaction Review
            </Button>
          </Dialog.Actions>
        </Dialog.Footer>
      </Dialog>
      <Box
        css={{
          display: "flex",
          alignItems: "center",
        }}
        onClick={(e) => {
          setOpen(true);
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Tag
          variant="pill"
          size="small"
          css={{
            display: "flex",
            gap: "$0h",
            background: "$rhino700",
            borderRadius: "29px",
            padding: "$0h $1",
            color: "$yellow500",
            fontWeight: "$heavy",
            filter: "$yellow500",
            alignItems: "center",
            "& svg": {
              width: "12px",
              height: "12px",
            },
          }}
        >
          <Exclamation width={12} height={12} viewBox="0 0 12 12" fill={colors.yellow500} />
          Estimated
        </Tag>
      </Box>
    </>
  );
};
