import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { format } from "date-fns";
import { useToggle } from "react-use";
import { Calendar } from "@puzzle/icons";
import { colors } from "@puzzle/theme";
import { styled, CSS } from "../stitches";
import DateRangePickerCalendar, {
  DateRangePickerCalendarProps,
  AllowEmpty,
  RangeValue,
} from "./DateRangePickerCalendar";
import { GroupBy, RangePreset } from "./types";
import { Popover } from "../Popover";
import { getFormatForGroupBy } from "./utils";
import { Toolbar } from "../Toolbar";
import { CalendarDate, getLocalTimeZone } from "@internationalized/date";

const PillLabel = styled("span", {
  display: "flex",
  alignItems: "center",
  height: "100%",
  padding: "0 $1h",

  ":disabled > &": {
    "&, *": {
      color: "$gray700",
    },
  },
});

const PresetLabel = styled(PillLabel, {
  color: "$gray500",
  fontWeight: "$bold",
  fontSize: "14px",
  lineHeight: "18px",
});

export const DateLabel = styled(PillLabel, {
  gap: "10px",
  fontSize: "14px",
  lineHeight: "18px",
  letterSpacing: "0.2px",
  color: "$gray50",

  variants: {
    isPlaceholder: {
      true: {
        color: "$gray300",
      },
      false: {},
    },
  },
});

// WARNING: This currently must be used in a Toolbar.
// If you need it elsewhere, make multiple variants/contexts that swap out the trigger's wrapper.
function DateRangePicker<
  // Don't supply this generic! It will infer from allowEmpty={[false, false]}
  Empty extends AllowEmpty = [false, false]
>({
  initialPreset,
  preset: _preset,
  presets,
  onPresetChange,
  onChange,
  groupBy,
  showLabel = true,
  placeholderText = "Select a date range",
  closeOnPresetChange = false,
  disabled,
  customLabelFormat,
  customTrigger,
  iconColor,
  css,
  popoverCss,
  ...props
}: DateRangePickerCalendarProps<Empty> & {
  initialPreset?: RangePreset;
  preset?: RangePreset;
  onPresetChange?: (preset: RangePreset) => void;
  groupBy?: `${GroupBy}`;
  showLabel?: boolean;
  placeholderText?: string;
  closeOnPresetChange?: boolean;
  customLabelFormat?: string;
  customTrigger?: ReactNode;
  iconColor?: string;
  css?: CSS;
  popoverCss?: CSS;
}) {
  const [open, toggleOpen] = useToggle(false);
  const [presetState, setPresetState] = useState<RangePreset | undefined>(initialPreset);
  const preset = _preset ?? presetState;
  const setPreset = onPresetChange ?? setPresetState;

  const [displayedDate, setDisplayedDate] = useState<RangeValue<[true, true]>>([
    undefined,
    undefined,
  ]);

  const displayedStartDate = open ? displayedDate[0] : props.value?.[0];
  const displayedEndDate = open ? displayedDate[1] : props.value?.[1];

  const handleValueChange = useCallback(
    (value: RangeValue<Empty>, preset?: RangePreset) => {
      onChange?.(value, preset);
      if (
        closeOnPresetChange &&
        // Hacky. Maybe handleValueChange should include the reason for the change?
        !preset?.key.includes("custom")
      ) {
        toggleOpen(false);
      }
    },
    [closeOnPresetChange, onChange, toggleOpen]
  );

  const formatDate = useCallback(
    (date: CalendarDate) => {
      const formatter = customLabelFormat
        ? customLabelFormat
        : preset || groupBy
        ? getFormatForGroupBy(groupBy || preset?.groupBy)
        : "MMM dd, yyyy";

      return format(date.toDate(getLocalTimeZone()), formatter);
    },
    [groupBy, preset, customLabelFormat]
  );

  const label = useMemo(() => {
    if (displayedStartDate && !displayedEndDate) {
      return formatDate(displayedStartDate);
    } else if (displayedStartDate && displayedEndDate) {
      return `${formatDate(displayedStartDate)} - ${formatDate(displayedEndDate)}`;
    } else {
      return placeholderText;
    }
  }, [displayedEndDate, displayedStartDate, formatDate, placeholderText]);

  return (
    <Popover
      side="bottom"
      align="start"
      sideOffset={8}
      css={popoverCss}
      open={open}
      onOpenChange={toggleOpen}
      data-testid="datepicker-trigger-popover"
      trigger={
        customTrigger ? (
          customTrigger
        ) : (
          <Toolbar.Button
            data-testid="datepicker-trigger"
            variant="custom"
            disabled={disabled}
            css={css}
          >
            {showLabel && <PresetLabel>{preset?.label ?? "Custom"}</PresetLabel>}
            <DateLabel isPlaceholder={label === placeholderText}>
              {label}
              <Calendar size={14} color={disabled ? "currentColor" : iconColor ?? colors.gray400} />
            </DateLabel>
          </Toolbar.Button>
        )
      }
    >
      <DateRangePickerCalendar<Empty>
        {...props}
        allowEmpty={props.allowEmpty}
        onChange={handleValueChange}
        presets={presets}
        preset={preset}
        onDisplayedValueChange={setDisplayedDate}
        onPresetChange={setPreset}
      />
    </Popover>
  );
}

export default DateRangePicker;
