import React from "react";
import { styled } from "@puzzle/ui";
import { DarkBackgroundLoadingBar } from "components/common/LoadingBar";

const TitleLoading = styled(DarkBackgroundLoadingBar, {
  width: "50%",
  height: "16px",
  marginBottom: "19px",
});

const BodyLoading = styled(DarkBackgroundLoadingBar, {
  width: "70%",
  height: "30px",
});

const LoadingMetrics = () => {
  return (
    <div>
      <TitleLoading />
      <BodyLoading />
    </div>
  );
};

export default LoadingMetrics;
