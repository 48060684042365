import { styled, Toolbar, IconButton } from "@puzzle/ui";
import { LedgerReportLevel, ReportFormat } from "graphql/types";

export const StyledToolbar = styled(Toolbar, {
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",

  "> button": {
    borderColor: "$rhino600",

    span: {
      fontWeight: "$normal",
    },
  },
});

export const DownloadIcon = styled(IconButton, {
  "&:hover, &:focus-visible, &[data-state=open]": {
    "&:not([disabled])": {
      color: "$gray100",
      background: "$gray50A04",
      borderRadius: "4px",
    },
  },
});

export const getLedgerReportLevels = () => {
  return [
    { value: LedgerReportLevel.Compact, label: "Compact" },
    { value: LedgerReportLevel.Summary, label: "Summary" },
    { value: LedgerReportLevel.Detailed, label: "Detailed" },
  ];
};

export const getReportFormats = () => {
  return [
    {
      value: ReportFormat.Excel,
      label: "Excel",
      description: "",
    },
    {
      value: ReportFormat.Csv,
      label: "CSV",
      description: "",
    },
    {
      value: ReportFormat.Json,
      label: "JSON",
      description: "",
    },
  ];
};
