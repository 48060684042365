import React from "react";
import { styled } from "@puzzle/ui";
import { SynchronizingThin } from "components/common/Synchronizing";

const Container = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "$1",

  color: "$gray100",
  fontWeight: "$heavy",
  fontSize: "$heading2",
  lineHeight: "$bodyXL",
});

const Calculating = ({ css }: { css?: React.CSSProperties }) => (
  <Container css={{ ...css }}>
    <span>Calculating</span>
    <SynchronizingThin />
  </Container>
);

export default Calculating;
