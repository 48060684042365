import React from "react";
import { useLocalDateFormatter } from "@puzzle/utils";

import { Area, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis } from "recharts";

import { S, Box, color } from "ve";

import { GraphToolTip } from "./GraphToolTip";
import { GraphDateRange } from "./GraphDateRange";
import { EmptyGraph, EmptyGraphVariant } from "./EmptyGraph";

export const RunwayGraph = ({
  todayCash,
  startDate,
  endDate,
}: {
  todayCash?: string;
  startDate?: string;
  endDate?: string;
}) => {
  const monthFormatter = useLocalDateFormatter({
    month: "long",
    year: "numeric",
  });

  if (!todayCash || !startDate || !endDate) {
    return <EmptyGraph variant={EmptyGraphVariant.SOLID} text="Not enough data to graph" />;
  }

  const data = [
    {
      date: startDate,
      value: parseFloat(todayCash),
    },
    {
      date: endDate,
      value: "0",
    },
  ];

  return (
    <Box css={{ width: "100%", height: "100%" }}>
      <ResponsiveContainer width="100%" height="85%">
        <ComposedChart data={data}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={color.purple500} stopOpacity={1.0} />
              <stop offset="100%" stopColor={color.purple900} stopOpacity={1.0} />
            </linearGradient>
          </defs>
          <Area type="monotone" dataKey="value" fill="url(#colorUv)" />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#B891E8"
            strokeWidth={3}
            dot={false}
            isAnimationActive={false}
          />

          <XAxis
            dataKey="date"
            tick={false}
            tickLine={false}
            axisLine={{ stroke: "#303030", strokeWidth: 1, strokeDasharray: "3 3" }}
          />

          <Tooltip
            wrapperStyle={{ outline: "none" }}
            cursor={false}
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                return (
                  <GraphToolTip date={payload[0].payload.date} value={payload[0].payload.value} />
                );
              }

              return null;
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <GraphDateRange
        startDate={startDate}
        endDate={endDate}
        css={{ paddingLeft: S.$1h, paddingRight: S.$1h, marginTop: "4px" }}
      />
    </Box>
  );
};
