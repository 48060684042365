import React from "react";

import { Crown } from "@puzzle/icons";
import { Button } from "@puzzle/ui";

import useMonetization from "components/monetization/useMonetization";
import { useExpensesExceededFeatureGate } from "./useExpensesExceededFeatureGate";

export const MetricTileDateGate = ({ children }: React.PropsWithChildren<unknown>) => {
  const { showUpgradeModal } = useMonetization();
  const { isExpensesExceeded } = useExpensesExceededFeatureGate();

  return isExpensesExceeded ? (
    <Button
      prefix={<Crown css={{ color: "$yellow400", marginRight: "$1" }} />}
      variant="minimal"
      css={{ color: "$yellow400" }}
      onClick={(e) => {
        e.stopPropagation();
        showUpgradeModal();
      }}
    >
      Upgrade to view
    </Button>
  ) : (
    <>{children}</>
  );
};
