import Big from "big.js";

import { ChartDatePoint, MetricOnDate } from "graphql/types";

import { DailyTotalCashValue } from "./DailyCashGraph";

export const computePercentageIncrease = (first: number, last: number) => {
  // Useless
  if (first === undefined || last === undefined) {
    return undefined;
  }

  // Trivial cases
  if (last === 0) {
    if (first === 0) {
      // Both are zero, no increase
      return 0;
    } else {
      // Last is zero, first positive, 100% increase
      if (first > last) {
        return 100;
      }

      // -100% isn't really meaningful here from 0, so return undefined
      return undefined;
    }
  }

  if (first === 0) {
    // First is zero, last positive, 100% decrease
    if (last > 0) {
      return -100;
    }

    // Avoid divide by 0 below!
    return undefined;
  }

  // Embiggen
  const bigFirst = new Big(first);
  const bigLast = new Big(last);

  const delta = bigLast.minus(bigFirst);
  const percentIncrease = delta.div(bigFirst).times(100).toNumber();

  // If you're having NaN problems, I feel bad for you, son
  if (Number.isNaN(percentIncrease) || !Number.isFinite(percentIncrease)) {
    return undefined;
  }

  return percentIncrease;
};

export const chartDataPointPercentageDiff = (
  data: ChartDatePoint[],
  chartDatePointDataKey: string
) => {
  if (!data) {
    return undefined;
  }
  if (data.length < 2) {
    return undefined;
  }

  const first = parseFloat(data[0].values.find((x) => x.key === chartDatePointDataKey)!.value);
  const last = parseFloat(
    data[data.length - 1].values.find((x) => x.key === chartDatePointDataKey)!.value
  );

  return computePercentageIncrease(first, last);
};

export const dailyTotalCashPercentageDiff = (
  dailyTotalCash: DailyTotalCashValue[] | undefined
): number | undefined => {
  if (!dailyTotalCash) {
    return undefined;
  }
  if (dailyTotalCash.length < 2) {
    return undefined;
  }

  const first = parseFloat(dailyTotalCash[0].totalCash.amount);
  const last = parseFloat(dailyTotalCash[dailyTotalCash.length - 1].totalCash.amount);

  return computePercentageIncrease(first, last);
};

export const metricOnDataPercentageDiff = (data: MetricOnDate[]): number | undefined => {
  if (!data) {
    return undefined;
  }
  if (data.length < 2) {
    return undefined;
  }

  const first = parseFloat(data[0].metric?.amount || "0");
  const last = parseFloat(data[data.length - 1].metric?.amount || "0");

  return computePercentageIncrease(first, last);
};
