import React, { useState, useRef, HTMLAttributes, useEffect, useCallback } from "react";
import useResizeObserver from "use-resize-observer";
import { styled, keyframes } from "@puzzle/ui";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { useToggle, useUpdateEffect } from "react-use";

import { ChevronDown } from "@puzzle/icons";
import { Box } from "ve";

const expandedStyle = {
  maxHeight: "90vh",
  boxShadow:
    "0px 0px 0px 1px #383147, 0px 4px 12px $colors$blackA10, 0px 16px 40px $colors$blackA20",
};

const collapsedStyle = {
  maxHeight: 0,
};

const expandAnimation = keyframes({
  from: collapsedStyle,
  to: expandedStyle,
});

const hideAnimation = keyframes({
  from: expandedStyle,
  to: collapsedStyle,
});

const Card = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  padding: "$2",
  margin: "0",
  border: "1px solid #383147",
  borderRadius: "$1",
  "&:hover, &:focus-within": {
    borderColor: "#4C4565",
  },
  transition: "border-color 0.1s ease-in",
});

const Inner = styled("div", {
  height: "100%",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const ExpandedInner = styled(Inner, {
  margin: "$2 0",
  height: "calc(var(---height) - var(--space-4) - 2px)",
});

const DialogContent = styled(DialogPrimitive.Content, {
  $$height: "0px",
  minHeight: "$$height",

  display: "flex",
  boxSizing: "border-box",
  overflowY: "hidden",
  outline: "none",

  position: "absolute",
  top: "-1px",
  left: "-1px",
  right: "-1px",
  zIndex: 1,

  padding: "0 $2",
  border: "1px solid #383147",
  borderRadius: "$1",
  backgroundColor: "$mauve800",

  fontSize: "13px",
  lineHeight: "18px",
  color: "$gray400",

  "&[data-state='open']": {
    animation: `${expandAnimation} 0.5s ease-in-out forwards`,
  },

  "&[data-state='closed']": {
    animation: `${hideAnimation} 0.5s ease-in-out forwards`,
  },

  [`${ExpandedInner}`]: {
    $height: "$$height",
  },
});

const expandButtonBaseStyle = {
  position: "absolute",
  right: 0,
  width: "32px",
  height: "32px",
  transform: "translate(25%, 25%)",
  appearance: "none",
  background: "transparent",
  border: "none",
  cursor: "pointer",
  overflow: "hidden",
  fontSize: "0",
  outline: "none",
  borderRadius: "$1",

  color: "$gray300",
  "&:hover": {
    color: "$gray100",
    backgroundColor: "rgba(248, 248, 250, 0.04)",
  },
};

export const ExpandButtonBottom = styled("button", {
  ...expandButtonBaseStyle,

  bottom: 0,
});

export const ExpandButtonTop = styled("button", {
  ...expandButtonBaseStyle,

  top: "-16px",
});

// TODO This context might be unnecessary if expandedContent is changed to a function
interface ExpandableCardContextType {
  expanded: boolean;
  toggleExpanded: () => void;
}

const ExpandableCardContext = React.createContext<ExpandableCardContextType | null>(null);

export const useExpandableCard = () => {
  const context = React.useContext(ExpandableCardContext);
  if (context === null) {
    throw new Error("ExpandableCardContext not found");
  }
  return context;
};

export const ExpandableCard = ({
  children,
  expandable,
  expandedContent,
  label,
  className,
  border = true,
  expandButtonOnTop,
  onExpand,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  border?: boolean;
  expandable?: boolean;
  expandedContent?: React.ReactNode;
  label?: string;
  expandButtonOnTop?: boolean;
  onExpand?: () => void;
}) => {
  const [height, setHeight] = useState(0);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [expanded, toggleExpanded] = useToggle(false);
  const [chevronExpanded, setChevronExpanded] = useState(expanded);

  // When we expand, a new dialog is rendered on top.
  // The chevron rotating needs to be delayed until the layer is switched out.
  useEffect(() => {
    setTimeout(() => {
      setChevronExpanded(expanded);
    }, 0);
  }, [expanded]);

  useUpdateEffect(() => {
    if (expanded) {
      onExpand?.();
    }
  }, [expanded]);

  useUpdateEffect(() => {
    if (expanded) {
      toggleExpanded();
    }
  }, [expandable]);

  const ref = useRef<HTMLDivElement>(null);
  useResizeObserver({
    ref: expandedContent && expandable ? ref : undefined,
    onResize: ({ height, ...props }) => {
      if (height && ref.current) {
        setHeight(ref.current.offsetHeight);
      }
    },
  });

  const [animationEnded, setAnimationEnded] = useState(false);

  const ExpandButton = expandButtonOnTop ? ExpandButtonTop : ExpandButtonBottom;

  const inner = (
    <>
      {children}

      {expandable && (
        <ExpandButton
          onClick={(e) => {
            e.stopPropagation();
            toggleExpanded();
          }}
        >
          <ChevronDown color="currentColor" size={16} rotate={chevronExpanded ? 180 : 0} />
        </ExpandButton>
      )}
    </>
  );

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!expanded && expandable) {
        event.stopPropagation();
        toggleExpanded();
      }
    },
    [expanded, toggleExpanded]
  );

  return (
    <Card
      ref={ref}
      {...props}
      onClick={handleOnClick}
      css={{ cursor: expanded ? "default" : "pointer" }}
    >
      <Inner>{inner}</Inner>

      <div ref={setContainer} />

      <DialogPrimitive.Root open={expanded} onOpenChange={toggleExpanded}>
        <DialogPrimitive.Portal container={container}>
          <DialogContent
            css={{ $$height: `${height}px`, zIndex: 2 }}
            onAnimationStart={() => setAnimationEnded(false)}
            onAnimationEnd={() => setAnimationEnded(true)}
            data-animation-ended={animationEnded}
          >
            <ExpandableCardContext.Provider value={{ expanded, toggleExpanded }}>
              <Box css={{ display: "flex", flexDirection: "column", minHeight: 0, flex: "1" }}>
                <ExpandedInner>{inner}</ExpandedInner>
                <Box css={{ display: "flex", flexDirection: "column", flex: "1", minHeight: 0 }}>
                  {expandedContent}
                </Box>
              </Box>
            </ExpandableCardContext.Provider>
          </DialogContent>
        </DialogPrimitive.Portal>
      </DialogPrimitive.Root>
    </Card>
  );
};
